div.dataTables_processing {
    position: absolute;
    top: -40px;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 2px;
    z-index: 9999;

    > div:last-child {
        position: relative;
        width: 80px;
        height: 15px;
        margin: 1em auto;

        > div {
            position: absolute;
            top: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: rgb(99 102 241 / var(--tw-text-opacity));
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
                left: 8px;
                animation: datatables-loader-1 0.6s infinite;
            }

            &:nth-child(2) {
                left: 8px;
                animation: datatables-loader-2 0.6s infinite;
            }

            &:nth-child(3) {
                left: 32px;
                animation: datatables-loader-2 0.6s infinite;
            }

            &:nth-child(4) {
                left: 56px;
                animation: datatables-loader-3 0.6s infinite;
            }
        }
    }
}

@keyframes datatables-loader-1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes datatables-loader-3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes datatables-loader-2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
