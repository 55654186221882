@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");
@import "tailwindcss/base";
@import "tailwindcss/components";

/* Additional styles */
@import "./additional-styles/range-slider.css";
@import "./additional-styles/datatables/processing.css";
@import "./additional-styles/datatables/ordering.css";
@import "./additional-styles/datatables/reorder.css";
@import "./additional-styles/datatables/datatables.css";
@import "./additional-styles/navigation.css";
@import "./additional-styles/select2.css";

@import "tailwindcss/utilities";

@layer base {
    :root {
        --range-thumb-size: 36px;
    }

    .h1 {
        @apply text-4xl font-extrabold tracking-tighter;
    }

    .h2 {
        @apply text-3xl font-extrabold tracking-tighter;
    }

    .h3 {
        @apply text-3xl font-extrabold;
    }

    .h4 {
        @apply text-2xl font-extrabold tracking-tight;
    }

    @screen md {
        .h1 {
            @apply text-5xl;
        }

        .h2 {
            @apply text-4xl;
        }
    }
}

/* TGP */
.tgp-bg-screenshot {
    @apply bg-green-700;
}
.tgp-bg-photo {
    @apply bg-blue-700;
}
.tgp-bg-dvd_cover {
    @apply bg-orange-700;
}
.tgp-bg-static_banner {
    @apply bg-pink-700;
}
.tgp-bg-animated_banner {
    @apply bg-rose-700;
}
.tgp-bg-video {
    @apply bg-violet-700;
}
.tgp-bg-background {
    @apply bg-cyan-800;
}
.tgp-bg-logo {
    @apply bg-sky-700;
}
.ss-hidden {
    display: none !important;
}

.btnn,
.btnn-lg,
.btnn-sm,
.btnn-xs {
    @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btnn {
    @apply px-3 py-2;
}

.btnn-lg {
    @apply px-4 py-3;
}

.btnn-sm {
    @apply px-2 py-1;
}

.btnn-xs {
    @apply px-2 py-0.5;
}

.action-btn {
    @apply inline-flex h-6 w-6 items-center justify-center rounded-md bg-gray-100 hover:bg-gray-200;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply text-sm text-slate-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-indigo-300 shadow-sm;
}

.form-input,
.form-textarea {
    @apply placeholder-slate-400;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-indigo-500 border border-slate-300;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Reset focus ring */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    &:focus {
        @apply ring-0;
    }
}

/* Switch element */
.form-switch {
    @apply relative select-none;
    width: 44px;

    label {
        @apply block overflow-hidden cursor-pointer h-6 rounded-full;

        > span:first-child {
            @apply absolute block rounded-full;
            width: 20px;
            height: 20px;
            top: 2px;
            left: 2px;
            right: 50%;
            transition: all 0.15s ease-out;
        }
    }

    input[type="checkbox"] {
        &:checked {
            + label {
                @apply bg-indigo-500;

                > span:first-child {
                    left: 22px;
                }
            }
        }

        &:disabled {
            + label {
                @apply cursor-not-allowed bg-slate-100 border border-slate-200;

                > span:first-child {
                    @apply bg-slate-400;
                }
            }
        }
    }
}

/* hides <input type="number"> arrows */
.input-hide-arrows {
    @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
}

.panel {
    @apply bg-white shadow-lg rounded-sm border border-slate-200 p-4 mb-4;
}

td.details-control {
    background: url("../svg/plus.svg") no-repeat center center;
    cursor: pointer;
}

tr.shown td.details-control {
    background: url("../svg/minus.svg") no-repeat center center;
}
