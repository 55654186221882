table.dataTable {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-spacing: 0;
    position: relative;

    /*
     * Header and footer styles
     */

    thead,
    tfoot {
        th {
            font-weight: bold;
        }
    }

    thead th,
    thead td {
        padding: 10px;

        &:active {
            outline: none;
        }
    }

    /*
     * Body styles
     */

    tbody {
        @apply text-sm;
        @apply divide-y;
        @apply divide-slate-200;

        th,
        td {
            padding: 8px 10px;
        }
    }

    /*Stripe classes - add "row-border" class to the table to activate */

    &.row-border tbody,
    &.display tbody {
        tr:first-child th,
        tr:first-child td {
            border-top: none;
        }
    }

    /*Stripe classes - add "cell-border" class to the table to activate */

    &.cell-border tbody {
        tr th:first-child,
        tr:first-child th,
        tr:first-child td {
            border-top: none;
        }
    }

    /*Stripe classes - add "stripe" class to the table to activate */

    &.stripe > tbody,
    &.display > tbody {
        > tr.odd > * {
            box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.023);
        }
    }

    /*Hover classes - add "hover" class to the table to activate */

    &.hover > tbody,
    &.display > tbody {
        > tr:hover > * {
            box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.015);
        }
    }

    /*Sort column highlighting - add "order-column" class to the table to activate */

    &.order-column,
    &.display {
        > tbody {
            tr > .sorting_1,
            tr > .sorting_2,
            tr > .sorting_3 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
            }
        }
    }

    &.display > tbody,
    &.order-column.stripe > tbody {
        > tr.odd {
            > .sorting_1 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.054);
            }

            > .sorting_2 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.047);
            }

            > .sorting_3 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.039);
            }
        }

        > tr.even {
            > .sorting_1 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.019);
            }

            > .sorting_2 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.011);
            }

            > .sorting_3 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.003);
            }
        }
    }

    &.display tbody,
    &.order-column.hover tbody {
        tr:hover {
            > .sorting_1 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.082);
            }

            > .sorting_2 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.074);
            }

            > .sorting_3 {
                box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.062);
            }
        }
    }

    &.compact {
        thead th,
        thead td {
            padding: 6px;
        }

        tfoot th,
        tfoot td {
            padding: 4px;
        }

        tbody th,
        tbody td {
            padding: 6px;
        }
    }
}

table.dataTable th,
table.dataTable td {
    box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
    /*Page length options */
    .dataTables_length {
        float: left;
        position: absolute;
        top: -3.5rem;
        @apply text-sm;
        @apply text-slate-500;
        @apply sm:text-left;

        select {
            --tw-border-opacity: 1;
            border-color: rgb(226 232 240 / var(--tw-border-opacity));
            line-height: 1.25rem;
            --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
            padding: 4px;
            background-image: none;
            appearance: auto;
            border-radius: 0.25rem;
        }
    }
    /*Filtering input */
    .dataTables_filter {
        float: right;
        text-align: right;
        position: absolute;
        top: -3.5rem;
        right: 0;
        @apply text-sm;
        @apply text-slate-500;
        @apply sm:text-left;

        input {
            --tw-border-opacity: 1;
            border-color: rgb(226 232 240 / var(--tw-border-opacity));
            padding: 0.5rem 0.75rem;
            line-height: 1.25rem;
            --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
            --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
            margin-left: 3px;
        }
    }
    /*Table info */
    .dataTables_info {
        clear: both;
        float: left;
        padding-bottom: 2em;
        @apply text-sm;
        @apply text-slate-500;
        @apply sm:text-left;
        position: absolute;
        bottom: -5rem;
    }
    /*Paging */
    .dataTables_paginate {
        float: right;
        padding-bottom: 2em;
        position: absolute;
        bottom: -5.7rem;
        right: 0;

        .paginate_button {
            @apply btnn;
            @apply bg-white;
            @apply border-slate-200;
            @apply hover:border-slate-300;
            @apply text-indigo-500;
            @apply mr-3;
            @apply last:mr-0;
            @apply cursor-pointer;

            &.current,
            &.disabled,
            &.disabled:hover,
            &.disabled:active {
                @apply text-slate-300;
                @apply cursor-not-allowed;
            }
        }

        .ellipsis {
            padding: 0 1em;
        }
    }
    /*Scrolling */
    .dataTables_scroll {
        clear: both;

        div.dataTables_scrollBody {
            -webkit-overflow-scrolling: touch;

            > table > thead > tr,
            > table > tbody > tr {
                > th,
                > td {
                    /*Setting v-align baseline can cause the headers to be visible */
                    vertical-align: middle;
                }

                > th > div.dataTables_sizing,
                > td > div.dataTables_sizing {
                    /*Hide the element used to wrap the content in the header for the body scrolling table */
                    height: 0;
                    overflow: hidden;
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }
    }

    &.no-footer {
        div.dataTables_scrollHead table.dataTable,
        div.dataTables_scrollBody > table {
            border-bottom: none;
        }
    }

    /*Self clear the wrapper */

    &:after {
        visibility: hidden;
        display: block;
        content: "";
        clear: both;
        height: 0;
    }
}

@media screen and (max-width: 767px) {
    .dataTables_wrapper {
        .dataTables_info,
        .dataTables_paginate {
            float: none;
            text-align: center;
        }

        .dataTables_paginate {
            margin-top: 0.5em;
        }
    }
}

@media screen and (max-width: 640px) {
    .dataTables_wrapper {
        .dataTables_length,
        .dataTables_filter {
            float: none;
            text-align: center;
        }

        .dataTables_filter {
            margin-top: 0.5em;
        }
    }
}

.datatable-container {
    position: relative;
    margin-top: 4.5rem;
}

#datatable_filter label input {
    @apply form-input;
    @apply focus:border-slate-300;
}
