/* Sorting - using :before (asc) and :after (desc) with UTF8 characters */
table.dataTable thead {
    > tr > th,
    > tr > td {
        &.sorting,
        &.sorting_asc,
        &.sorting_desc,
        &.sorting_asc_disabled,
        &.sorting_desc_disabled {
            cursor: pointer;
            position: relative;

            padding-right: 26px;

            &:before,
            &:after {
                position: absolute;
                display: block;
                opacity: 0.125;
                right: 10px;
                line-height: 9px;
                font-size: 0.8em;
            }

            &:before {
                bottom: 50%;
                content: "\25B2"; /* up arrow - ascending */
            }

            &:after {
                top: 50%;
                content: "\25BC"; /* down arrow - descending */
            }
        }

        &.sorting_asc:before,
        &.sorting_desc:after {
            opacity: 0.6;
        }

        &.sorting_desc_disabled:after,
        &.sorting_asc_disabled:before {
            display: none;
        }

        &:active {
            outline: none;
        }
    }
}

div.dataTables_scrollBody table.dataTable thead {
    > tr > th,
    > tr > td {
        &:before,
        &:after {
            display: none;
        }
    }
}
